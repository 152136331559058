/* 
 * Carousel
 * ver1.1
 * Create a carousel out of a list (used for banners, latest news, latest events, customer quotes etc)
 */

/*global  jQuery*/

(function ($) {
	"use strict";

	/**
	 *	Move to the an item
	 */
	function show (obj_Carousel, int_Index) {
		var obj_ItemList = obj_Carousel.find('.carousel--items'),
			obj_Item = obj_ItemList.find('.item-' + int_Index+1),
			arr_PreviousItems = obj_Item.prevAll(),
			int_PreviousItemCount = arr_PreviousItems.length,
			arr_Controls = obj_Carousel.find('.carousel--controls .carousel--controls-item'),
			arr_Items = obj_Carousel.find('.carousel--items').children(),
			int_ItemCount = arr_Items.length;

		 arr_Controls.removeClass('selected');
		 
		 if (int_Index === arr_Items.length-2) {
			 arr_Controls.eq(0).addClass('selected');
		 } else if (int_Index < 0) {
			 arr_Controls.eq(int_ItemCount-3).addClass('selected');
		 } else {
			 arr_Controls.eq(int_Index).addClass('selected');
		 }
		 
		 
		 obj_ItemList.animate({
			left: -100 * (int_Index+1) + '%'
			}, 500, "swing", function() {
				// Switcharoony!
				
				if (int_Index === arr_Items.length-2) {
					int_Index = 0;
					$(this).css({ 
						left: '-100%'
					 }); 
				} else if (int_Index < 0) {
					$(this).css({ 
						left: -100 * (int_ItemCount-2) + '%'
					 }); 	
				}
		});  
		 
		obj_Item.siblings().css('opacity', '0');
		
		// Make the all items opaque
		obj_Carousel.find('.carousel--items > *').css('opacity', '1');
		
		obj_ItemList = null;
		obj_Item = null;
		arr_PreviousItems = null;
		arr_Controls = null;
	}
	
	function createControls (int_ItemCount, obj_Settings) {
		var obj_ControlList, obj_Control, obj_ControlLink, str_Html;
		
		// Limit the number of items available
		int_ItemCount = Math.min(int_ItemCount, obj_Settings.maxItems);

		// Don't need controls when only 1 item exists
		if(int_ItemCount <= 1) {
			return '';
		}
		
		obj_ControlList = $('<ul>', {
			'class': 'carousel--controls'
		});
		
		// Create each control
		for(var i = 0; i < int_ItemCount; i += 1) {
			// Create the control link
			obj_ControlLink = $('<a>', {
				'href': '#',
				'text': (i+1)
			});
			
			// Create the control
			obj_Control = $('<li>', {
				'class': 'carousel--controls-item'
			});
			
			// Add the control to the container
			obj_ControlLink.appendTo(obj_Control);
			obj_Control.appendTo(obj_ControlList);
		}
		

		str_Html = ($('<div>').append(obj_ControlList)).html();
		return str_Html;
	}
	
	function createContainers () {
		var obj_Carousel, obj_Clip, str_Html;
		
		obj_Carousel = $('<div>', {
			'class': 'carousel--wrapper'
		});
		obj_Clip = $('<div>', {
			'class': 'carousel--clip'
		});
		
		obj_Clip.appendTo(obj_Carousel);
		
		
		str_Html = ($('<div>').append(obj_Carousel)).html();

		return str_Html;
	}

	var obj_Methods = {
			
		/**
		 *	Start auto rotating the carousel
		 */
		start: function () {
			var obj_Carousel = $(this),
				obj_Settings = obj_Carousel.data('carousel-settings'),
				int_IntervalHandle;
			
			int_IntervalHandle = setInterval((function () {
				$(this).carousel('next');
			}).bind(this), obj_Settings.Interval);
			
			obj_Carousel.data('carousel-interval', int_IntervalHandle);

			obj_Carousel = null;
		},
		
		/**
		 *	Stop auto rotating the carousel
		 */
		stop: function () {
			var obj_Carousel = $(this),
				int_IntervalHandle = obj_Carousel.data('carousel-interval');
			
			if (!isNaN(int_IntervalHandle)) {
				clearInterval(int_IntervalHandle);
			}
			
			obj_Carousel.removeData('carousel-interval');
		},
		
		/**
		 *	Start auto rotating the carousel
		 */
		next: function () {
			var obj_Carousel = $(this),
				arr_Controls = obj_Carousel.find('.carousel--controls .carousel--controls-item'),
				obj_CurrentControl = arr_Controls.filter('.selected'),
				obj_NextControl,
				int_Index;

			
			// If there's no selected control then goto the first
			if(obj_CurrentControl.length === 0) {
				show(obj_Carousel, 0);
				return;
			}
			
			// Try to get the next control
			obj_NextControl = obj_CurrentControl.next('.carousel--controls-item');
			
			// If there isn't a next control then get the first one
			if(obj_NextControl.length === 0) {
				show(obj_Carousel, arr_Controls.length);
				//show(obj_Carousel, 0);
			} else {
				int_Index = arr_Controls.index(obj_NextControl);
				show(obj_Carousel, int_Index);
			}

			obj_Carousel = null;
			arr_Controls = null;
			obj_CurrentControl = null;
			obj_NextControl = null;
		},
		
		/**
		 *  
		 */
		prev: function () {
			var obj_Carousel = $(this),
				arr_Controls = obj_Carousel.find('.carousel--controls .carousel--controls-item'),
				obj_CurrentControl = arr_Controls.filter('.selected'),
				obj_PrevControl,
				int_Index;
			
			// If there's no selected control then goto the first
			if(obj_CurrentControl.length === 0) {
				show(obj_Carousel, 0);
				return;
			}
			
			// Try to get the previous control
			obj_PrevControl = obj_CurrentControl.prev('.carousel--controls-item');
			
			// If there isn't a previous control then get the last one
			if(obj_PrevControl.length === 0) {
				show(obj_Carousel, -1);
			} else {
				int_Index = arr_Controls.index(obj_PrevControl);
				show(obj_Carousel, int_Index);
			}

			obj_Carousel = null;
			arr_Controls = null;
			obj_CurrentControl = null;
			obj_PrevControl = null;
		},

		/**
		 *	Set up the plugin on all elements
		 *	@param		object		obj_Options		An object containing parameters for the plugin
		 *
		 */
		init: function (obj_Options) {
			return this.each(function () {
				var	obj_ItemList = $(this),
					str_ClickNamespaced = 'click.carousel',
					str_ContainersHtml, str_ControlsHtml, obj_Settings;

				// Only 1 item? Get us out of here ASAP!
				if(obj_ItemList.find('.carousel--item').length <= 1) {
					obj_ItemList.addClass('single');
					return '';
				}

				str_ContainersHtml = createContainers();
				
				// Wrap the item list in the carousel containers
				var obj_Carousel = $(str_ContainersHtml);
				obj_Carousel.insertBefore(obj_ItemList);
				obj_ItemList.remove().addClass('carousel--items');
				obj_ItemList.appendTo(obj_Carousel.children('.carousel--clip'));
	
				// Define the plugin options
				obj_Settings = $.extend({
					'maxItems': 50,
					'Interval': 5000,
					'autoScroll': false,
					'indexSlide': true
				}, obj_Options);
				
				// Collect a list of carousel items
				var arr_Items = obj_Carousel.find('.carousel--items').children();
				var int_ItemCount = arr_Items.length;		


				if (int_ItemCount > 1) {	
					obj_Carousel.append('<a class="carousel--control left" href="#myCarousel" data-slide="prev"><span>&lt;</span></a><a class="right carousel--control" href="#myCarousel" data-slide="next"><span>&gt;</span></a>');
				}
				

				// Make all but the first item transparent
				arr_Items.filter(':gt(0)').css({
					'display': 'block',
					'opacity': '0'
				});

				// Save the plugin options in the element
				obj_Carousel.data('carousel-settings', obj_Settings);
				
				// Add controls
				str_ControlsHtml = createControls(int_ItemCount, obj_Settings);
				var obj_Controls = $(str_ControlsHtml);
				obj_Controls.appendTo(obj_Carousel);
				
				// Add item classes to the items
				for (var i = 0; i < int_ItemCount; i += 1) {
					arr_Items.eq(i).addClass('item-' + (i+1));
				}
				
				// Pad the carousel out with fake items to allow for continious scrolling
				
				arr_Items.filter(':first').before(arr_Items.slice(int_ItemCount-1).clone().addClass('cloned'));
				arr_Items.filter(':last').after(arr_Items.slice(0, 1).clone().addClass('cloned'));
				
				// refresh
				arr_Items = obj_Carousel.find('.carousel--items').children();
				int_ItemCount = arr_Items.length;
				
				// Resize elements
				obj_ItemList.width((100 * int_ItemCount) + '%');
				arr_Items.css('width', (100 / int_ItemCount) + "%");


				
				// Add events when clicking a goto link
				obj_Carousel.delegate('.carousel--controls .carousel--controls-item a', str_ClickNamespaced, function(e) {

					var obj_ControlLink = $(this),
						obj_Carousel = obj_ControlLink.parents('.carousel--wrapper').eq(0),
						obj_ParentControl = obj_ControlLink.parents('.carousel--controls-item').eq(0),
						obj_ControlList = obj_ParentControl.parents('.carousel--controls').children('.carousel--controls-item'),
						int_Index = obj_ControlList.index(obj_ParentControl);
					
					e.preventDefault();
					
					show(obj_Carousel, int_Index);
				});
				
				
				if (obj_Settings.indexSlide) {
				
					obj_Carousel.delegate('.selectors .carousel--controls .carousel--controls-item a', str_ClickNamespaced, function(e) {

						var obj_ControlLink = $(this),
							obj_Carousel = obj_ControlLink.parents('.carousel--wrapper').eq(0).parents('.carousel--wrapper').eq(0),
							obj_ParentControl = obj_ControlLink.parents('.carousel--controls-item').eq(0),
							obj_ControlList = obj_ParentControl.parents('.selectors').children('.carousel--controls-item'),
							int_Index = obj_ControlList.index(obj_ParentControl);
												
						e.preventDefault();
						
						show(obj_Carousel, int_Index+1);
					});
				}
				
				
				// ensure all banner list items have their display css value set to 'block' - works with the carousels.scss display: none setting
				$('ul.carousel li').each(function() {
					$(this).css({
						'display': 'block'
					});
				});
				
				// Add events when clicking a prev link
				obj_Carousel.delegate('a.left', str_ClickNamespaced, function(e) {
					var obj_Carousel = $(this).parents('.carousel--wrapper').eq(0);
					e.preventDefault();
					obj_Carousel.carousel('prev');
				});
				
				// Add events when clicking a next link
				obj_Carousel.delegate('a.right', str_ClickNamespaced, function(e) {
					var obj_Carousel = $(this).parents('.carousel--wrapper').eq(0);
					e.preventDefault();
					obj_Carousel.carousel('next');
				});
							
				
				// Activate the first item
				show(obj_Carousel, 0);

				// Start the carousel
				if(obj_Settings.autoScroll === true) {
					obj_Carousel.carousel('start');
				}

				// Add hover pause 
				obj_Carousel.hover(
					function() {
					  var obj_Carousel = $(this);
					  obj_Carousel.carousel('stop');
					},
					function() {
						var obj_Carousel = $(this);
						obj_Carousel.carousel('start');
					}
				);


				// Release the variables
				obj_ItemList = null;
				arr_Items = null;
				obj_Carousel = null;
				obj_Controls = null;
			});
		}
	
	};

	/**
	 *	Plugin entry point
	 */
	$.fn.carousel = function (str_Method) {
		var mix_Return;

		if (obj_Methods[str_Method]) {
			mix_Return = obj_Methods[str_Method].apply(this, Array.prototype.slice.call(arguments, 1));
		} else if (typeof str_Method === 'object' || !str_Method) {
			mix_Return = obj_Methods.init.apply(this, arguments);
		} else {
			$.error('Method ' +  str_Method + ' does not exist on jQuery.carousel');
		}

		return mix_Return;
	};

}(jQuery));